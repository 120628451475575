.input {
  &.mdc-text-field {
    background-color: transparent !important;

    &:before {
      background-color: transparent;
    }

    &.mdc-text-field--focused {
      .mdc-floating-label {
        color: $secondary;
      }
    }

    .mdc-floating-label {
      font-weight: 600;
      font-size: $medium;
      left: 0;
      color: $gray;
    }

    .mdc-text-field__input {
      border: none;
      border-bottom: 1px solid $grayLight !important;
      padding-left: 0;
      padding-bottom: 0;
      caret-color: $secondary;
      resize: none;
    }

    .mdc-line-ripple {
      background-color: $secondary;
    }

    .mdc-notched-outline {
      * {
        border: none;
      }

      .mdc-notched-outline__leading {
        display: none;
      }
    }
  }
}

.checkbox {
  position: relative;
  display: flex;
  align-items: center;

  * {
    cursor: pointer;
  }

  &.checkbox--active {
    span {
      &:before {
        content: "";
        position: absolute;
        top: calc(50% - 4px);
        left: calc(50% - 4px);
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: $secondary;
      }
    }
  }

  label {
    font-weight: 400;
    padding-left: 10px;
    font-size: 15px;
    color: $text;
    margin: 0;
  }

  span {
    position: relative;
    height: 16px;
    width: 16px;
    border: 1px solid $secondary;
    border-radius: 50%;
  }
}
