.modal {
  &.modal--project {
    overflow-y: auto;

    .processes {
      padding: 40px 54px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 20px;
    }

    .process__item {
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 20px;
      align-self: baseline;

      h4 {
        position: relative;
        padding: 34px 32px;

        b {
          color: $secondary;
          font-size: $large;
          position: relative;
        }

        span {
          position: absolute;
          top: 36px;
          left: 0;
          font-size: 72px;
          color: $background;
        }
      }

      ul {
        display: grid;
        grid-row-gap: 10px;
        margin-top: 20px;

        li {
          display: flex;
          align-items: center;
        }

        p {
          flex: 1;
        }

        b {
          width: 32px;
          height: 32px;
          margin-right: 10px;
          border: 1px solid;
          border-radius: 100%;
          @include flex-basic()
        }
      }
    }
  }

  .images {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    padding: 0 110px 80px;
  }

  .statistics {
    padding: 60px 136px 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .statistic__item {
    @include flex-basic();
  }

  .scopes {
    ul {
      display: grid;
      padding: 80px 246px 200px;
      justify-items: center;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 120px;

      li {
        text-align: center;

        i {
          font-size: 80px;
          color: $secondary;
        }
      }
    }
  }

  .modal__container {
    max-width: 1064px;
    margin: 60px auto;
    background-color: $white;
    border-radius: 8px;
    overflow: hidden;
  }

  .modal__header {
    padding: 70px 20px;
    background-position-y: 24%;

    .modal__header__inner {
      position: relative;
      z-index: 1;
    }

    * {
      color: $white;
    }

    h3 {
      @include flex-basic;
    }

    h2 {
      color: $secondaryLight;
      margin: 40px 0 20px;
    }

    p {
      max-width: 336px;
      margin: auto;
      font-size: $large;
      line-height: 24px;
    }
  }

  .modal__close {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: transparent;
    z-index: 2;

    i {
      font-size: $xLarge;
      color: $white;
    }
  }
}

@media only screen and (max-width: 600px) {

  .modal {
    &.modal--project {
      .processes {
        display: block;
        padding: 20px 15px;
      }
      .process__item {
        margin-bottom: 30px;
      }
    }
    .images {
      display: block;
      padding: 15px;
      .img__item {
        margin-bottom: 30px;
      }
    }
    .statistics {
      display: block;
      padding: 60px;
      .statistic__item {
        margin-bottom: 40px;
      }
    }
    .scopes {
      ul {
        display: block;
        padding: 60px;
        li {
          margin-bottom: 40px;
        }
      }
    }
  }

}
