.section {
  padding: 60px 80px;
  position: relative;
}

.section__title {
  text-align: center;
  margin-bottom: 40px;
}

.header {
  @include flex-basic;
  justify-content: space-between;
  margin: 16px 0 50px;

  .btn:hover,
  .btn--estimation {
    background-color: $white;

    span {
      color: $secondary;
    }
  }

  .icon-logo {
    color: $white;
  }
}

.section--banner {
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  width: 100%;
  height: 100vh;
  padding-top: 0;
  padding-bottom: 0;

  &.section--banner--subpage {
    .typing__wrapper {
      text-align: left;
      width: 100%;
      margin-left: initial;
      padding-left: 100px;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($text, 0.6);
    filter: blur(2px);
  }

  .logo__box {
    display: block;
    width: 162px;
  }

  .section__content {
    position: relative;
    z-index: 2;
    height: 100%;

    h1 {
      margin: 20% 0 5%;

      p {
        font-size: $xxxLarge;
        font-weight: 600;
        line-height: 72px;
      }
    }
  }

  .typing__wrapper {
    max-width: 980px;
    margin: 8% auto 0;
    text-align: center;

    p {
      line-height: 24px;
    }
  }
}

.section--services {
  .service__container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 20px;
  }

  .service__item {
    border-radius: 8px;
    background-color: $white;
    text-align: center;
    padding: 60px 16px 24px;

    img {
      width: 60px;
    }

    h5 {
      margin: 20px 0;
    }

    li {
      margin: 10px 0;
    }
  }
}

.features__container {
  display: flex;
  justify-content: space-around;
  position: relative;
  margin-top: 80px;

  &.features__container--secondary {
    margin-top: 60px;

    &:before {
      content: '';
      position: absolute;
      top: 40px;
      left: 15%;
      width: calc(70%);
      height: 1px;
      background-color: $secondary;
    }

    .feature__item {
      .icon {
        width: 80px;
        height: 80px;
        border: 1px solid $secondary;

        i {
          font-size: 32px;
        }
      }

      .number {
        position: absolute;
        top: 0;
        left: calc(50% - 40px);
        width: 28px;
        height: 28px;
        border-radius: 100%;
        background-color: $secondary;
        color: $white;
        @include flex-basic;
      }
    }
  }

  &.features__container--left {
    margin-top: 100px;

    .feature__item {
      text-align: left;

      .icon {
        margin-left: initial;
      }
    }
  }

  .feature__item {
    text-align: center;
    position: relative;
    flex: 1;

    &:not(:last-child) {
      margin-right: 5%;
    }

    &:hover {
      .btn {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .icon {
      margin: 0 auto;
    }

    h4 {
      margin: 30px 0 10px;
    }

    .btn {
      margin: 50px auto 0;
      max-width: 180px;
      opacity: 0;
      transform: translateY(-20px);
      transition: all 0.3s ease;
    }
  }
}

.section--case-study {
  padding: 40px 0 160px;

  .slick-slider {
    .slick-slide {
      padding: 0 80px;

      .section--case-study__content {
        grid-column-gap: 60px;
      }

      .box__left {
        background-color: $grayLight;
        background-size: cover;
        background-repeat: no-repeat;
        background-position-y: 40%;
      }

      .box__right {
        width: 80%;

        * {
          color: $white;
        }

        h3 {
          margin-bottom: 20px;
        }

        h4 {
          margin-bottom: 10px;
        }

        .btn {
          margin: 30px 0 50px;
        }
      }
    }

    .slick-arrow {
      position: absolute;
      z-index: 1;
      top: 0;
      font-size: 0;
      padding: 0;
      height: 100%;
      width: 80px;
      background: rgb(255,182,30);
      background: linear-gradient(90deg, rgba(255,182,30,0) 0%, rgba(255,182,30,1) 100%);

      &:before {
        font-family: 'icomoon', serif !important;
        color: #C78600;
      }

      &.slick-next {
        right: 0;

        &:before {
          content: "\e90d";
          font-size: 24px;
        }
      }

      &.slick-prev {
        left: 0;
        transform: rotate(180deg);

        &:before {
          content: "\e90d";
          font-size: 24px;
        }
      }
    }
  }
}

.section--stories {
  background-color: $white;

  .story__container {
    display: grid;
    grid-template-columns: auto 25% 25%;
    grid-column-gap: 20px;
    margin-bottom: 40px;

    .story__card {
      position: relative;
      border-radius: 8px;
      overflow: hidden;
      height: 450px;
      background-size: cover;
      background-repeat: no-repeat;

      &.story__card--main {
        .story__card__content {
          width: 50%;
        }
      }
    }

    .story__card__content {
      position: absolute;
      bottom: 20px;
      left: 20px;
      max-width: calc(100% - 40px);

      h4 {
        background: white;
        display: inline;
      }

      .story__card__author {
        display: inline-flex;
        background-color: white;
        border-radius: 20px;
        padding: 3px 15px 3px 3px;
        align-items: center;
        margin-top: 10px;

        .avatar {
          width: 30px;
          height: 30px;
          margin-right: 10px;
        }

        span {
          font-size: $small;
        }
      }
    }
  }
}

.section--contact {
  h3 {
    margin-bottom: 40px;
  }
}

.contact__form {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 30px;
}

.footer__list {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  padding: 40px 0 100px;
  border-top: 1px solid $grayLight;

  h3 {
    margin-bottom: 20px;
  }

  li {
    line-height: 28px;
  }
}

.section--estimate {
  padding: 80px 40px;
  text-align: center;
  position: relative;
  z-index: 1;

  h2 {
    max-width: 680px;
    margin: 0 auto 30px;
    color: $white;
  }

  p {
    max-width: 720px;
    margin: 0 auto 30px;
    font-size: $large;
    line-height: 24px;
  }
}

.section--industry {
  .industry__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    margin-top: 60px;
  }

  .industry__item {
    height: 12vw;
    border-radius: 8px;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: grayscale(100%);
    position: relative;

    &:before {
      content: '';
      background-color: rgba($text, 0.4);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    h3 {
      color: $white;
      position: relative;
    }
  }
}

.process__map {
  display: flex;
  justify-content: center;
  margin: 100px 0;

  .process__map__item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:not(:last-child) {
      margin-right: 50px;

      &:before {
        content: '';
        width: 80px;
        height: 1px;
        background-color: $secondary;
        position: absolute;
        right: -60px;
        top: 40px;
      }
    }

    .icon {
      border: 1px solid $secondary;
      width: 80px;
      height: 80px;
      margin-bottom: 20px;

      i {
        font-size: 32px;
      }
    }
  }

  span {
    border-radius: 100%;
    width: 28px;
    height: 28px;
    background-color: $secondary;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    color: $white;
  }
}

.special__box {
  margin-top: 80px;
  display: grid;
  grid-template-columns: 306px auto;
  grid-column-gap: 128px;

  .box__right {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px 60px;

    p {
      margin-top: 20px;
      font-size: $large;
      line-height: 24px;
    }
  }

  .btn {
    margin-top: 40px;
  }
}

.card--vertical {
  display: grid;
  grid-template-columns: 306px auto;
  grid-column-gap: 120px;
  max-width: 80%;
  margin: 80px auto 0;

  .card--vertical__title {
    align-self: flex-start;
  }
}

.card__service {
  padding-top: 80px;

  &.card__service--reverse {
    .card__service__header {
      margin-left: auto;
      padding: 0 188px 70px 80px;
      text-align: right;

      .number {
        left: initial;
        right: 136px;
      }

      p {
        margin-left: auto;
      }
    }

    .card__project {
      grid-template-columns: 305px auto;
      grid-auto-flow: dense;
    }

    .card__project__image {
      grid-column: 2;
    }

    .card__project__description {
      text-align: right;

      h3 {
        &:before {
          left: initial;
          right: 0;
        }
      }
    }
  }

  .card__service__header {
    position: relative;
    padding: 0 80px 70px 188px;
    box-sizing: content-box;

    .number {
      color: rgba($gray, 0.1);
      font-size: 144px;
      line-height: 144px;
      font-weight: 900;
      position: absolute;
      top: -46px;
      left: 136px;
    }

    h2 {
      margin-bottom: 10px;
    }

    p {
      max-width: 520px;
    }
  }

  .card__service__body {
    padding: 0 80px 70px;
  }

  .card__service__progress__header {
    width: 100%;
    max-width: 440px;
    margin: 80px auto 70px;

    h3 {
      margin-bottom: 20px;
    }
  }

  .card__service__images {
    height: 584px;
  }

  .btn--view__more {
    margin: 50px 0 0 40px;
  }
}

.card__project {
  display: grid;
  grid-template-columns: auto 305px;
  grid-column-gap: 86px;
  margin-top: 80px;

  .card__project__image {
    height: 527px;
    border-radius: 8px;
    background-color: $grayLight;
    background-size: cover;
    background-position: center;
  }

  .card__project__description {
    margin-top: 10px;

    h3 {
      padding-bottom: 43px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        bottom: 20px;
        left: 0;
        width: 50px;
        height: 3px;
        background-color: $secondary;
      }
    }

    p {
      margin: 10px 0 30px;
    }
  }
}

.showcase {
  display: grid;
  grid-row-gap: 60px;
  max-width: 970px;
  margin: 60px auto 0;
  padding: 0 16px;

  .showcase__item {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    &.showcase__item--reverse {
      grid-auto-flow: dense;

      .showcase__img {
        grid-column: 2;
      }

      .showcase__content {
        padding: 0 128px 0 0;
        text-align: right;

        h4 {
          &:before {
            right: 0;
          }
        }
      }
    }

    .showcase__img {
      width: 100%;
      min-height: 330px;
      border-radius: 8px;
      background-color: $grayLight;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .showcase__content {
      padding: 0 0 0 128px;
    }
  }
}

.statistics {
  .statistics__inner {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 70px;
    padding: 160px 80px;

    h1 {
      font-size: 96px;
      font-weight: 500;
      margin-bottom: 30px;
    }
  }
}

.quote {
  .quote__inner {
    padding: 116px 80px 116px 134px;
    position: relative;
    z-index: 1;

    * {
      font-size: $xLarge;
      line-height: 32px;
      color: $white;
    }

    .icon {
      position: absolute;
      top: 116px;
      left: 80px;

      i {
        color: $grayLight;
        font-size: 44px;
      }
    }

    .author {
      display: flex;
      align-items: center;
      margin-top: 40px;

      h4 {
        margin-left: 10px;
      }
    }
  }
}

.quote__service {
  .quote__service__inner {
    padding: 50px 80px 100px;
  }

  h2 {
    margin-bottom: 20px;
  }

  .card__list {
    margin-top: 60px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 70px;

    .card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .card__header {
    width: 100%;
    height: 70px;
    margin-bottom: 20px;
    img {
      height: 100%;
      width: auto;
      max-width: 180px;
    }
  }

  .card__footer {
    display: flex;
    margin-top: 32px;
  }
}

.team {
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  p {
    font-size: $large;
    margin-top: 4px;
  }
}

.estimate__selections {
  padding: 0 80px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 60px;

  .selection__item {
    @include flex-basic();
    flex-direction: column;
    padding: 70px 20px 110px;
    border-radius: 8px;
    box-shadow: 0px 0px 20px rgba($text, 0.1);
    cursor: pointer;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: 28px;
      left: calc(50% - 12px);
      width: 24px;
      height: 24px;
      background-color: $background;
      border-radius: 100%;
    }

    &.selection__item--active {
      border: 1px solid $secondary;

      &:after {
        background-color: $grayDark;
      }
    }

    i {
      font-size: 100px;
      margin-bottom: 20px;
      color: $secondary;
    }
  }
}

.input-range {
  max-width: 1064px;
  margin: 60px auto;

  .input-range__track {
    background-color: $background;
  }

  .input-range__track--active {
    background-color: $secondary;
  }

  .input-range__slider {
    background-color: $white;
    width: 24px;
    height: 24px;
    border: 4px solid $secondary;
    margin-top: -14px;
  }

  .input-range__label--value {
    top: 30px;

    span {
      font-size: $xLarge;
      font-weight: 700;
    }
  }

  .input-range__label--max,
  .input-range__label--min {
    display: none;
  }
}


@media only screen and (max-width: 600px) {

  .wrapper {
    overflow-x: hidden;
  }

  .section__title {
    margin-top: 40px;
    margin-bottom: 60px;
  }

  .section {
    padding: 20px 15px;
    &.section--footer {
      display: none;
    }
    &.section--testimonials {
      padding-top: 80px;
    }
  }

  .section--banner {
    width: 100vw;
    .max__width {
      padding: 30% 0;
      .typing__wrapper {
        margin: 15% auto;
      }
    }
    &.section--banner--subpage {
      color: red;
      .max__width {
        padding: 5% 0;
        .typing__wrapper {
          text-align: center;
          padding: 0;
        }
      }
    }
    .section__content {
      margin-top: 0 !important;
      h1 {
        margin: 15% auto;
      }
    }
  }

  .section--services {

    .service__container {
      display: block;
      .service__item {
        margin-bottom: 30px;
      }
    }

  }

  .features__container {
    display: block;
    .feature__item {
      width: 70%;
      margin: 0 auto 60px;
      text-align: center;
      &:not(:last-child) {
        margin: 0 auto 60px;
        text-align: center;
      }
    }
    &.features__container--left {
      .feature__item {
        text-align: center;
        .icon {
          margin-left: auto;
        }
      }
    }
    &.features__container--secondary {
      &:before {
        opacity: 0;
      }
    }
  }

  .section--case-study {
    .slick-slider {
      .slick-arrow {
        display: none !important;
      }
      .slick-slide {
        padding: 0;
        .section--case-study__content {
          display: block !important;
        }
        .box__left {
          width: 100%;
          height: 225px;
          margin-bottom: 40px;
        }
        .box__right {
          width: 100%;
          text-align: center;
          .two__column {
            display: none !important;
          }
        }
      }
    }
  }

  .section--contact {
    .two__column__item {
      text-align: center;
      margin-bottom: 40px;
    }
    h3 {
      padding-top: 40px;
    }
    p {
      padding-bottom: 10px;
    }
  }

  .card__service {
    padding-top: 100px;
    .card__service__header {
      padding: 0 15px 0 95px;
      .number {
        left: 15px;
      }
    }
    &.card__service--reverse {
      .card__service__header {
        padding: 0 95px 0 15px;
        .number {
          right: 15px;
        }
      }
    }
    .card__service__body {
      padding: 0 15px 80px;
      .card__project {
        display: none;
      }
      .card__service__progress {
        .features__container {
          .feature__item {
            width: 70%;
            margin: 0 auto 80px;
            .btn {
              opacity: 1;
              transform: none;
              margin-top: 20px;
            }
          }
        }
      }
    }
  }

  .quote__service {
    .quote__service__inner {
      padding: 60px 15px 20px;
      .card__list {
        display: block;
        .card {
          margin-bottom: 60px;
        }
      }
    }
  }

  .process__map {
    display: grid;
    .process__map__item {
      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: 100px;
        &:before {
          width: 1px;
          height: 60px;
          left: 50%;
          top: 110%;
        }
      }
    }
  }

  .section--industry {
    .industry__list {
      grid-template-columns: repeat(2, 1fr);
    }
    .industry__item {
      height: 50vw;
    }
  }

  .card__project {
    display: none;
  }

  .special__box {
    display: block;
    text-align: center;
    .box__left {
      .border {
        &:before {
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    .box__right {
      display: block;
      width: 70%;
      margin: 60px auto;
      .card {
        margin-bottom: 40px;
        .icon {
          margin: auto;
        }
      }
    }
  }

  .card--vertical {
    display: block;
  }

  .statistics {
    .statistics__inner {
      display: block;
      text-align: center;
      padding: 60px;
      .statistic__item {
        margin-bottom: 60px;
      }
    }
  }

  .quote {
    .quote__inner {
      padding: 60px 30px;
      .icon {
        top: 20px;
        left: 30px;
        z-index: -1;
      }
      .author {
        margin-top: 20px;
      }
    }
  }

  .team {
    display: block;
    .team__item {
      margin-bottom: 60px;
    }
  }

  .estimate__selections {
    display: block;
    padding: 0;
    .selection__item {
      align-items: center;
      justify-content: end;
      flex-direction: row;
      padding: 20px 20px 20px 60px;
      margin-bottom: 20px;
      i {
        font-size: 60px;
        margin-bottom: 0;
        margin-right: 20px;
      }
      &:after {
        bottom: 50%;
        transform: translateY(50%);
        left: 20px;
      }
    }
  }

  .input-range {
    max-width: 80vw;
  }

  .header {
    margin: 0;
    .btn {
      display: none;
    }
  }

}
