@import '../../assets/styles/_Color.scss';

@keyframes btnAnimation {
  0% {
    transform-origin: center bottom;
    transform: scaleY(0);
  }
  50% {
    transform-origin: center bottom;
    transform: scaleY(1);
  }
  51% {
    transform-origin: center top;
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0);
  }
}

@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 100%
  }
}
