@import '../../assets/styles/_FontSize.scss';
@import '../../assets/styles/_Color.scss';

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  i {
    font-size: $xLarge;
    color: $white;
  }

  &.icon--circle {
    width: 42px;
    height: 42px;
    border-radius: 100%;
    background-color: $gray;

    &:hover {
      background-color: $secondary;
    }
  }

  &.icon--secondary {
    width: 60px;
    height: 60px;
    background-color: $background !important;

    i {
      color: $secondary;
    }
  }
}
