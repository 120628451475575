.page--clients {
  .section--projects {
    display: grid;
    grid-template-columns: 200px auto;
    grid-column-gap: 128px;
    align-items: flex-start;

    .sidebar {
      display: grid;
      grid-row-gap: 20px;

      .btn {
        border-radius: 8px;
      }
    }

    .project__list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 40px 20px;
    }

    .project__item {
      height: 340px;
      cursor: pointer;
      margin-bottom: 50px;

      &:hover {
        i {
          transform: scale(1.2) rotate(10deg);
        }
      }

      .project__item--img {
        height: 100%;
        border-radius: 8px;
        overflow: hidden;
        margin-bottom: 20px;

        i {
          display: block;
          height: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          transition: all 0.5s ease;
          background-position: center;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {

  .page--clients {
    .section--projects {
      display: block;
      .sidebar {
        display: none;
      }
      .project__list {
        display: block;
      }
      .project__item {
        text-align: center;
        margin-bottom: 100px;
      }
    }
  }

}
