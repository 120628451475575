.page--home {
  .social__network__box {
    padding: 30px 0;
    border-bottom: 1px solid $white;
  }

  .scroll__down {
    @include flex-basic;
    position: absolute;
    bottom: 20px;
    width: 100%;

    i {
      margin-right: 10px;
    }
  }

  .section--banner {
    .section__content {
      h1 {
        margin: 8% auto 5%;
      }
    }
  }
}

@media only screen and (max-width: 600px) {

  .page--home {
    .social__network__box {
      display: none;
    }
  }

}
