.btn {
  position: relative;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  span {
    font-weight: 600;
    letter-spacing: 0.5px;
  }

  &.btn--medium {
    border-radius: 30px;
    padding: 0 30px;
    height: 40px;
    line-height: 19px;

    span {
      font-size: $medium;
    }
  }

  &.btn--large {
    padding: 0 40px;
    height: 70px;
    border-radius: 60px;

    span {
      font-size: $normal;
      line-height: 25px;
    }
  }

  &.btn--primary {
    background-color: $white;

    span {
      color: $text !important;
    }
  }

  &.btn--secondary {
    background-color: $secondary;

    span {
      color: $white;
    }
  }

  &.btn--outline {
    border: 1px solid $text;
    background-color: transparent;

    span {
      color: $text;
    }
  }

  &.btn--outline-white {
    border: 1px solid $white;
    background-color: transparent;

    span {
      color: $white;
    }
  }

  &.btn--disabled {
    opacity: 0.5;
  }
}
