$small: 12px;
$medium: 14px;
$normal: 16px;
$large: 18px;
$xLarge: 24px;
$xxLarge: 36px;
$xxxLarge: 52px;

$font-size: ('small': $small, 'medium': $medium, 'normal': $normal, 'large': $large, 'xLarge': $xLarge, 'xxLarge': $xxLarge, 'xxxLarge': $xxxLarge);
@each $name, $size in $font-size {
  .font__size--#{$name} {
    font-size: $size;
  }
}
