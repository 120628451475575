@font-face {
  font-family: 'icomoon';
  src:  url('../icon-font/icomoon.eot?ufitv0');
  src:  url('../icon-font/icomoon.eot?ufitv0#iefix') format('embedded-opentype'),
  url('../icon-font/icomoon.ttf?ufitv0') format('truetype'),
  url('../icon-font/icomoon.woff?ufitv0') format('woff'),
  url('../icon-font/icomoon.svg?ufitv0#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arm-flex:before {
  content: "\e972";
}
.icon-boxing-glove:before {
  content: "\e973";
}
.icon-chemical-weapon:before {
  content: "\e974";
}
.icon-chess-knight:before {
  content: "\e975";
}
.icon-comment-alert:before {
  content: "\e976";
}
.icon-dumbbell:before {
  content: "\e977";
}
.icon-map-check:before {
  content: "\e978";
}
.icon-presentation-play:before {
  content: "\e979";
}
.icon-seed-outline:before {
  content: "\e97a";
}
.icon-trophy:before {
  content: "\e97b";
}
.icon-voice:before {
  content: "\e97c";
}
.icon-ui-ux:before {
  content: "\e96e";
}
.icon-backend:before {
  content: "\e96f";
}
.icon-frontend:before {
  content: "\e970";
}
.icon-close:before {
  content: "\e971";
}
.icon-emoticon-excited:before {
  content: "\e947";
}
.icon-fast-forward:before {
  content: "\e948";
}
.icon-feather:before {
  content: "\e949";
}
.icon-feature-search:before {
  content: "\e94a";
}
.icon-file-chart:before {
  content: "\e94b";
}
.icon-filter-variant:before {
  content: "\e94c";
}
.icon-finance:before {
  content: "\e94d";
}
.icon-format-quote-close:before {
  content: "\e94e";
}
.icon-format-text:before {
  content: "\e94f";
}
.icon-glasses:before {
  content: "\e950";
}
.icon-google-assistant:before {
  content: "\e951";
}
.icon-group:before {
  content: "\e952";
}
.icon-home-analytics:before {
  content: "\e953";
}
.icon-image-filter:before {
  content: "\e954";
}
.icon-library:before {
  content: "\e955";
}
.icon-lightbulb:before {
  content: "\e956";
}
.icon-magnify:before {
  content: "\e957";
}
.icon-map-marker:before {
  content: "\e958";
}
.icon-memory:before {
  content: "\e959";
}
.icon-monitor-screenshot:before {
  content: "\e95a";
}
.icon-monitor:before {
  content: "\e95b";
}
.icon-piggy-bank:before {
  content: "\e95c";
}
.icon-poll:before {
  content: "\e95d";
}
.icon-presentation:before {
  content: "\e95e";
}
.icon-radar:before {
  content: "\e95f";
}
.icon-remote-desktop:before {
  content: "\e960";
}
.icon-reply:before {
  content: "\e961";
}
.icon-scale-balance:before {
  content: "\e962";
}
.icon-scissors-cutting:before {
  content: "\e963";
}
.icon-shield-alert:before {
  content: "\e964";
}
.icon-shield-key:before {
  content: "\e965";
}
.icon-sort-ascending:before {
  content: "\e966";
}
.icon-sort-descending:before {
  content: "\e967";
}
.icon-transit-connection-variant:before {
  content: "\e968";
}
.icon-trello:before {
  content: "\e969";
}
.icon-trending-neutral:before {
  content: "\e96a";
}
.icon-vector-square:before {
  content: "\e96b";
}
.icon-view-dashboard:before {
  content: "\e96c";
}
.icon-volume-high:before {
  content: "\e96d";
}
.icon-chevron-down:before {
  content: "\e907";
}
.icon-account-search:before {
  content: "\e924";
}
.icon-account-star:before {
  content: "\e925";
}
.icon-alert-decagram:before {
  content: "\e926";
}
.icon-alert:before {
  content: "\e927";
}
.icon-anchor:before {
  content: "\e928";
}
.icon-antenna:before {
  content: "\e929";
}
.icon-arm-flex1:before {
  content: "\e92a";
}
.icon-arrange:before {
  content: "\e92b";
}
.icon-arrow-decision:before {
  content: "\e92c";
}
.icon-arrow-expand:before {
  content: "\e92d";
}
.icon-atom-variant:before {
  content: "\e92e";
}
.icon-autorenew:before {
  content: "\e92f";
}
.icon-balloon:before {
  content: "\e930";
}
.icon-beer:before {
  content: "\e931";
}
.icon-bulletin-board:before {
  content: "\e932";
}
.icon-call-merge:before {
  content: "\e933";
}
.icon-call-split:before {
  content: "\e934";
}
.icon-cellphone-iphone:before {
  content: "\e935";
}
.icon-chart-line-variant:before {
  content: "\e936";
}
.icon-checkbox-multiple:before {
  content: "\e937";
}
.icon-chevron-triple-up:before {
  content: "\e938";
}
.icon-code-tags-check:before {
  content: "\e939";
}
.icon-comment-account:before {
  content: "\e93a";
}
.icon-compass:before {
  content: "\e93b";
}
.icon-crystal-ball:before {
  content: "\e93c";
}
.icon-cube-scan:before {
  content: "\e93d";
}
.icon-database-settings:before {
  content: "\e93e";
}
.icon-debug-step-into:before {
  content: "\e93f";
}
.icon-desktop-mac-dashboard:before {
  content: "\e940";
}
.icon-details:before {
  content: "\e941";
}
.icon-developer-board:before {
  content: "\e942";
}
.icon-devices:before {
  content: "\e943";
}
.icon-donkey:before {
  content: "\e944";
}
.icon-draw:before {
  content: "\e945";
}
.icon-drawing:before {
  content: "\e946";
}
.icon-logo:before {
  content: "\e908";
}
.icon-ab-testing:before {
  content: "\e909";
}
.icon-android:before {
  content: "\e90a";
}
.icon-apple-ios:before {
  content: "\e90b";
}
.icon-application:before {
  content: "\e90c";
}
.icon-arrow-right:before {
  content: "\e90d";
}
.icon-auto-fix:before {
  content: "\e90e";
}
.icon-beer1:before {
  content: "\e90f";
}
.icon-brain:before {
  content: "\e910";
}
.icon-chart:before {
  content: "\e911";
}
.icon-cloud-sync:before {
  content: "\e912";
}
.icon-code-tags:before {
  content: "\e913";
}
.icon-compass1:before {
  content: "\e914";
}
.icon-console-line:before {
  content: "\e915";
}
.icon-cube-unfolded:before {
  content: "\e916";
}
.icon-database:before {
  content: "\e917";
}
.icon-eye-circle:before {
  content: "\e918";
}
.icon-hand:before {
  content: "\e919";
}
.icon-python:before {
  content: "\e91a";
}
.icon-layers-triple:before {
  content: "\e91b";
}
.icon-nodejs:before {
  content: "\e91c";
}
.icon-projector-screen:before {
  content: "\e91d";
}
.icon-react:before {
  content: "\e91e";
}
.icon-robot:before {
  content: "\e91f";
}
.icon-rocket:before {
  content: "\e920";
}
.icon-sign-direction:before {
  content: "\e921";
}
.icon-target:before {
  content: "\e922";
}
.icon-wrench:before {
  content: "\e923";
}
.icon-twitter:before {
  content: "\e906";
}
.icon-facebook:before {
  content: "\e904";
}
.icon-instagram:before {
  content: "\e900";
}
.icon-linkedin:before {
  content: "\e905";
}
.icon-github:before {
  content: "\e901";
}
.icon-dribbble:before {
  content: "\e903";
}
.icon-behance:before {
  content: "\e902";
}
