.page--about-us {
  .card__about-us__header {
    width: 100%;
    max-width: 50%;
    box-sizing: content-box;

    h2 {
      margin-bottom: 10px;
    }

    .btn {
      margin-top: 30px;
    }
  }

  .card__about-us__body {
    &.card__about-us__body--empty {
      height: 400px;
      width: 100%;
      background-color: $background;
    }

    .card__list {
      margin-top: 80px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 90px 50px;

      .card__item__img {
        width: 60px;
        height: 60px;
        border-radius: 100%;
        background-color: $background;
      }

      h4 {
        margin: 20px 0 10px;
      }
    }
  }

  .process {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 20px;
    margin-top: 40px;

    .process__item {
      position: relative;
      border-radius: 8px;
      background-color: $white;
      padding: 58px 20px 16px;
      min-height: 400px;

      span {
        position: absolute;
        top: 20px;
        left: 20px;
        font-weight: 600;

        font-size: 72px;
        line-height: 98px;
        color: $background;
      }

      h4 {
        margin-left: 32px;
        margin-bottom: 74px;
        position: relative;
      }
    }
  }
}

@media only screen and (max-width: 600px) {

  .page--about-us {
    .card__about-us__header {
      max-width: fit-content;
      text-align: center;
      margin-bottom: 60px;
      h2 {
        margin-top: 60px;
      }
    }
    .card__about-us__body {
      .card__list {
        display: block;
        .card__item {
          margin-bottom: 40px;
          text-align: center;
          width: 70%;
          margin-left: auto;
          margin-right: auto;
          .icon {
            margin: auto;
          }
        }
      }
    }
    .process {
      display: block;
      .process__item {
        min-height: auto;
        margin-bottom: 30px;
        h4 {
          margin-bottom: 30px;
        }
      }
    }
  }

}
