.section--overview__container {
  h2 {
    margin: 80px 0 10px;
  }

  .process__image {
    max-width: 540px;
    margin: 56px auto;
  }
}

.box {
  margin-top: 40px;

  h4 {
    margin-bottom: 10px;
  }

  ul {
    li {
      display: flex;
      align-items: center;
      margin: 10px 0;

      .icon {
        background-color: $secondary;
        width: 18px;
        height: 18px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;

        i {
          font-size: 12px;
        }
      }
    }
  }
}
