@import '../../assets/styles/_Color.scss';

$font-weights: ("thin": 300, "regular": 400, "bold": 600, "extra-bold": 700);
@each $name, $size in $font-weights {
  .font__weight--#{$name} {
    font-weight: $size;
  }
}

$text-align: (left, center, right);
@each $name in $text-align {
  .text--#{$name} {
    text-align: $name;
  }
}

$display: (block, flex, grid);
@each $name in $display {
  .d__#{$name} {
    display: $name;
  }
}

$align-items: (start, center, end);
@each $name in $align-items {
  .align__items--#{$name} {
    align-items: $name;
  }
}

$justify-content: ("start": flex-start, "end": flex-end, "center": center, "between": space-between, "around": space-around);
@each $name, $position in $justify-content {
  .justify__content--#{$name} {
    justify-content: $position;
  }
}

$total: 20;
@for $i from 0 through $total {
  // Margin
  .m__t--#{$i*2} {
    margin-top: #{$i*2}px !important;
  }
  .m__b--#{$i*2} {
    margin-bottom: #{$i*2}px !important;
  }
  .m__r--#{$i*2} {
    margin-right: #{$i*2}px !important;
  }
  .m__l--#{$i*2} {
    margin-left: #{$i*2}px !important;
  }
  .m__v--#{$i*2} {
    margin-top: #{$i*2}px !important;
    margin-bottom: #{$i*2}px !important;
  }
  .m__h--#{$i*2} {
    margin-left: #{$i*2}px !important;
    margin-right: #{$i*2}px !important;
  }
  .m--#{$i*2} {
    margin: #{$i*2}px !important;
  }

  // Padding
  .p__t--#{$i*2} {
    padding-top: #{$i*2}px !important;
  }
  .p__b--#{$i*2} {
    padding-bottom: #{$i*2}px !important;
  }
  .p__r--#{$i*2} {
    padding-right: #{$i*2}px !important;
  }
  .p__l--#{$i*2} {
    padding-left: #{$i*2}px !important;
  }
  .p__v--#{$i*2} {
    padding-top: #{$i*2}px !important;
    padding-bottom: #{$i*2}px !important;
  }
  .p__h--#{$i*2} {
    padding-left: #{$i*2}px !important;
    padding-right: #{$i*2}px !important;
  }
  .p--#{$i*2} {
    padding: #{$i*2}px !important;
  }

  // Top
  .top--#{$i*2} {
    top: #{$i*2}px !important;
    bottom: initial !important;
  }

  // Bottom
  .bottom--#{$i*2} {
    bottom: #{$i*2}px !important;
    top: initial !important;
  }

  // Right
  .right--#{$i*2} {
    right: #{$i*2}px !important;
    left: initial !important;
  }

  // Left
  .left--#{$i*2} {
    left: #{$i*2}px !important;
    right: initial !important;
  }
}

.min__w {
  &--220 {
    min-width: 220px !important;
  }
}

.page__content {
  z-index: 99;
  position: relative;
  background-color: $background;
}

.mark {
  height: 100vh;
  width: 100%;
}

.border {
  position: relative;
  padding-bottom: 50px;

  &:before {
    content: "";
    position: absolute;
    bottom: 30px;
    width: 50px;
    height: 3px;
    background-color: $secondary;
  }

  &.border--left {
    left: 0;
  }

  &.border--right {
    right: 0;
  }

  &.border--center {
    text-align: center;

    &:before {
      left: calc(50% - 25px);
    }
  }

  &--around {
    border-radius: 100%;
  }
}

.text--white {
  color: $white;
}

.bg--white {
  background-color: $white;
}

.logo {
  font-size: 42px;
}

.two__column {
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
}

.cursor {
  cursor: pointer;
}

.full__width {
  width: 100%;
}

.menu--sub {
  border-bottom: 1px solid $background;
  position: sticky;
  top: 0;
  z-index: 9;
  background-color: $white;

  &.menu--sub__fixed {
    position: fixed;
    width: 100%;
    border: none;
    box-shadow: 0 0 6px 4px rgba($text, 0.1);
  }

  li {
    cursor: pointer;
  }

  .active {
    color: $secondary;
  }

  .header {
    margin: 0;
    padding: 16px 80px;
    border-bottom: 1px solid $background;

    .icon-logo {
      color: $text;
    }

    .btn {
      border-color: $secondary;

      span {
        color: $secondary;
      }
    }
  }
}

.max__width {
  max-width: 1440px;
  margin: auto;

  &--medium {
    max-width: 842px;
    margin: 0 auto 8%;
  }

  &--small {
    max-width: 450px !important;
  }
}

.relative {
  position: relative;
}

.background,
.react-parallax {
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background-position: center;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba($text, 0.5);
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}

.avatar {
  background-color: $white;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  overflow: hidden;

  &.avatar--medium {
    width: 60px;
    height: 60px;
  }
}

@media only screen and (max-width: 600px) {

  .two__column {
    display: block !important;
  }

  .menu--sub  {
    display: none;
  }

  .page__content {
    padding-top: 60px;
  }

}
