.page--sub__flutter {
  .features__container--flutter {
    margin-top: 60px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px 95px;
  }
}

@media only screen and (max-width: 600px) {
  .page--sub__flutter {
    .features__container--flutter {
      display: block;
    }
  }
}
