@import '../../assets/styles/_Color.scss';

.testimonials__content {
  padding: 124px 230px;
  background-color: $background;
  display: flex;

  .card {
    flex: 1;
    padding: 40px 32px;
    display: grid;
    grid-template-rows: repeat(3, 1fr);

    &:not(:last-child) {
      border-right: 1px solid $border;
    }
  }

  .card__header {
    width: 100%;
    height: 70px;
    margin-bottom: 20px;
    img {
      height: 100%;
      width: auto;
      max-width: 180px;
    }
  }

  .card__footer {
    display: flex;
    margin-top: 32px;
  }
}

@media only screen and (max-width: 600px) {
  .testimonials__content {
    display: block;
    padding: 60px 15px;
    .card {
      padding: 20px 0;
      &:not(:last-child) {
        border-right: 0;
        border-bottom: 1px solid $border;
      }
    }
  }
}
