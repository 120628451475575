@import '../../assets/styles/_FontSize.scss';
@import '../../assets/styles/_Color.scss';

.menu {
  display: flex;
  align-items: center;

  &.menu--home {
    * {
      color: $white;
    }
  }

  * {
    color: $gray;
  }

  .menu__item {
    &:not(:last-child) {
      margin-right: 40px;
    }

    &.menu__item--active {
      a {
        color: $white;
        text-decoration: line-through;
      }
    }
  }
}

.menu__wrapper {
  .mobile--menu {
    display: none;
  }
}

@media only screen and (max-width: 600px) {

  .menu {
    display: none;
  }

  .menu__wrapper {
    .mobile--menu {
      display: block;
      color: white;
    }
  }

}
