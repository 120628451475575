$text: #000000;
$white: #FFFFFF;
$secondary: #FFB61E;
$secondaryLight: #FFE858;
$background: #F2F2F2;
$border: #E0E0E0;
$gray: #828282;
$grayLight: #BDBDBD;
$grayDark: #4F4F4F;
$link: #2D9CDB;

$text-color: ('black': $text, 'white': $white, 'secondary': $secondary, 'secondaryLight': $secondaryLight, 'gray': $gray, 'link': $link, 'default': $background);
@each $name, $color in $text-color {
  .text--#{$name} {
    color: $color;
  }

  .bg--#{$name} {
    background-color: $color;
  }
}
