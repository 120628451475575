@import './lib/TextField';
@import './lib/TextField';
@import './lib/InputRange';
@import './lib/Notification';
@import 'Reset';
@import 'Minxin';
@import 'Common';
@import 'IconFont';
@import 'Animation';
@import 'Color';
@import 'FontSize';
@import 'Button';
@import 'Form';
@import 'Page';
@import 'Modal';
// Pages
@import './pages/Home';
@import './pages/Clients';
@import './pages/AboutUs';
// Sub pages
@import './pages/ProductDesignSprint';

body, html {
  font-family: 'Nunito', sans-serif;
  background-color: $grayLight;
  scroll-behavior: auto;

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Nunito', sans-serif;
    font-weight: normal;
    box-sizing: border-box;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    color: $text;
    font-size: $medium;
    line-height: 20px;
  }
}

h1, h2, h3, h4, h5, b {
  font-weight: 600;

  span {
    font-size: inherit;
    font-weight: inherit;
  }
}

h1 {
  font-size: $xxxLarge;
  line-height: 72px;
}

h2 {
  font-size: $xxLarge;
  line-height: 50px;
}

h3 {
  font-size: $xLarge;
  line-height: 34px;
}

h4 {
  font-size: $large;
  line-height: 26px;
}

h5 {
  font-size: $normal;
  line-height: 22px;
}

img {
  width: 100%;
}

a {
  &:hover {
    color: $secondary;
  }

  &:focus {
    outline: none;
  }
}

.btn--scroll__top {
  @include flex-basic();
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  z-index: 99;
  background-color: $secondary;

  i {
    transform: rotate(180deg);
    color: $white;
    font-size: $xLarge;
  }
}

.wrapper--scroll:not(.wrapper--menu) {
  .header--mobile {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 10px 2px rgba(49, 31, 31, 0.1);
    padding: 12px 15px;

    .logo {
      color: $text;
      font-size: 30px;
    }
  }

  .btn--menu {
    span {
      background-color: $text;
    }
  }
}

.header--mobile {
  display: none;
  transition: all 0.4s linear;

  @media (max-width: 767px) {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    padding: 20px 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.header--mobile--open {
      .logo {
        color: $text;
      }
    }

    .logo {
      color: $white;
      transition: all 0.4s linear;
    }
  }
}

.btn--menu {
  width: 24px;
  height: 24px;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  background-color: transparent;

  &.btn--menu--open {
    span {
      background-color: $text;

      &:nth-child(1),
      &:nth-child(6) {
        transform: rotate(45deg);
      }

      &:nth-child(2),
      &:nth-child(5) {
        transform: rotate(-45deg);
      }

      &:nth-child(1) {
        left: 2px;
        top: 5px;
      }

      &:nth-child(2) {
        left: calc(50% - 2px);
        top: 5px;
      }

      &:nth-child(3) {
        left: -50%;
        opacity: 0;
      }

      &:nth-child(4) {
        left: 100%;
        opacity: 0;
      }

      &:nth-child(5) {
        left: 2px;
        top: 13px;
      }

      &:nth-child(6) {
        left: calc(50% - 2px);
        top: 13px;
      }
    }
  }

  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 50%;
    background: $white;
    opacity: 1;
    transform: rotate(0deg);
    transition: .25s ease-in-out;

    &:nth-child(even) {
      left: 50%;
      border-radius: 0 2px 2px 0;
    }

    &:nth-child(odd) {
      left: 0px;
      border-radius: 2px 0 0 2px;
    }

    &:nth-child(1),
    &:nth-child(2) {
      top: 2px;
    }

    &:nth-child(3),
    &:nth-child(4) {
      top: 8px;
    }

    &:nth-child(5),
    &:nth-child(6) {
      top: 14px;
    }
  }
}

.menu--mobile {
  background-color: $white;
  position: fixed;
  z-index: 998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px 15px;
  overflow-y: auto;
  transform: translate(100vw, 0);
  transition: transform 0.3s ease-out;

  &.menu--mobile--open {
    transform: translate(0);
    transition: transform 0.3s ease-in;

    .menu__inner {
      margin-top: 80px;
    }

    .menu__item {
      margin-bottom: 24px;

      &.menu__item--active {
        a {
          color: $secondary;
        }
      }

      &:last-child {
        a {
          color: $secondary;
        }
      }

      a {
        font-size: $xLarge;
        font-weight: 600;
      }
    }
  }

  .menu__footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-top: 1px solid $border;
    padding-top: 20px;
    height: calc(100% - 368px);

    .social__network {
      justify-content: space-between;

      li {
        margin-right: 0;

      }

      * {
        color: $text;
      }
    }

    .company__info {
      display: grid;
      grid-row-gap: 5px;
    }
  }

  * {
    color: $text;
  }
}
